import {
  useContractWrite,
  usePrepareContractWrite,
  useQueryClient,
  useWaitForTransaction,
} from "wagmi";

import { ZombiumStaking } from "../config";
import { useEffect } from "react";
import { REVALIDATED_KEYS } from "./const";

export function useStake({
  tokenIds,
  enabled,
}: {
  tokenIds: readonly bigint[];
  enabled?: boolean;
}) {
  const { config } = usePrepareContractWrite({
    ...ZombiumStaking,
    functionName: "stake",
    args: [tokenIds],
    enabled,
  });

  const { data, write } = useContractWrite(config);
  const { isSuccess, isLoading: isStaking } = useWaitForTransaction({
    hash: data?.hash,
  });

  const client = useQueryClient();

  useEffect(() => {
    if (isSuccess) {
      client.invalidateQueries({
        predicate: (query) =>
          REVALIDATED_KEYS.includes((query.queryKey[0] as any)["scopeKey"]),
      });
    }
  }, [client, isSuccess]);

  return { stake: write, isSuccess, isStaking };
}
