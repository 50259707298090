import React from "react";
import { formatEther } from "ethers";

import { useGetRewardPerToken } from "../../hooks";

export default function Info() {
  const { rewardPerToken, isLoading } = useGetRewardPerToken();

  return (
    <div className="text-white mx-auto my-4">
      <div className="max-w-xl flex flex-col items-center justify-center text-2xl mx-auto my-3">
        <div className="">
          You can earn{" "}
          <b>
            {isLoading ? (
              <>Loading...</>
            ) : rewardPerToken !== undefined ? (
              (parseFloat(formatEther(rewardPerToken)) * 3600 * 24).toFixed(5)
            ) : (
              <>0</>
            )}{" "}
            <a
              className="underline"
              href="https://www.shibariumscan.io/token/0x3a71e24Aec6F1004780450dB9Bfaf6DCB72965fB"
              target="_blank"
              rel="noreferrer"
            >
              Sharbi
            </a>
          </b>{" "}
          per staked NFT daily.
        </div>
      </div>
    </div>
  );
}
