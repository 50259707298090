import { useCallback, useState } from "react";

const useSelect = () => {
  const [selected, setSelected] = useState<number[]>([]);

  const selectToken = useCallback(
    (token: number) => {
      const index = selected.findIndex((item) => item === token);

      if (index >= 0) {
        const newSelect = [...selected];
        newSelect.splice(index, 1);
        setSelected([...newSelect]);
      } else {
        setSelected([...selected, token]);
      }
    },
    [selected]
  );

  const clear = useCallback(() => {
    setSelected([]);
  }, []);

  return { selected, selectToken, clear };
};

export default useSelect;
