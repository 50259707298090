import { useAccount, useContractRead } from "wagmi";

import { ZombiumStaking } from "../config";

export function useGetRewardsEarned() {
  const { address } = useAccount();
  const { data, ...rest } = useContractRead({
    ...ZombiumStaking,
    functionName: "userStakeInfo",
    args: [address!],
    enabled: !!address,
    staleTime: 2,
  });

  return { rewards: data?.[1], ...rest };
}
