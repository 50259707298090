import { useMemo } from "react";
import { Address, useAccount, useContractRead, useContractReads } from "wagmi";
import { ERC721Enumerable__factory } from "@sleepyshibs/staking";

import { NFT_ADDRESS } from "../config";

const SleepyShibContract = {
  address: NFT_ADDRESS as Address,
  abi: ERC721Enumerable__factory.abi,
} as const;

export function useGetOwnedTokens() {
  const { address } = useAccount();
  const { data: balance, ...rest } = useContractRead({
    ...SleepyShibContract,
    functionName: "balanceOf",
    args: [address!],
    enabled: !!address,
    scopeKey: "ownedTokens",
  });

  const fetchData = useMemo(() => {
    if (!balance || !address) return undefined;

    const toFetch = [];
    for (let i = 0; i < Number(balance); i++) {
      toFetch.push({
        ...SleepyShibContract,
        functionName: "tokenOfOwnerByIndex",
        args: [address, i],
      });
    }
    return toFetch;
  }, [address, balance]);

  const { data: result, isLoading } = useContractReads({
    contracts: fetchData,
    enabled: !!fetchData,
  });

  const tokenIds = useMemo(() => {
    return result?.map((r) => r?.result as bigint) ?? [];
  }, [result]);

  return { tokenIds, ...rest, isLoading };
}
