import React from "react";
import zombiumImg from "../../assets/imgs/zombium.jpeg";

export function NotConnected() {
  return (
    <div
      id="not-connected"
      className="fixed top-0 left-0 z-10 w-screen h-screen flex justify-center items-center bg-black"
    >
      <img
        src={zombiumImg}
        alt="shibs"
        className="fixed w-[50rem] opacity-10 pointer-events-none"
      />
      <p className="text-6xl font-[Gulfs] text-white text-center leading-relaxed -rotate-6 p-5">
        You need to connect a wallet <br />
        to jump into staking!
      </p>
    </div>
  );
}
