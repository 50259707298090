import { FC, ReactNode } from "react";
import { ToastContainer } from "react-toastify";
import { getDefaultWallets, RainbowKitProvider } from "@rainbow-me/rainbowkit";
import { configureChains, createConfig, WagmiConfig } from "wagmi";
import { goerli } from "wagmi/chains";
import { alchemyProvider } from "wagmi/providers/alchemy";
import { publicProvider } from "wagmi/providers/public";
import { jsonRpcProvider } from "wagmi/providers/jsonRpc";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";

import "@rainbow-me/rainbowkit/styles.css";
import "react-toastify/dist/ReactToastify.css";

import { IS_PRODUCTION, shibarium } from "./config";

const CHAINS = IS_PRODUCTION ? [shibarium] : [shibarium, goerli];

const { chains, publicClient } = configureChains(CHAINS, [
  publicProvider(),
  alchemyProvider({
    apiKey: process.env.REACT_APP_ALCHEMY_ID || "",
  }),
  jsonRpcProvider({
    rpc: (chain) => {
      if (chain.id === shibarium.id) {
        return { http: shibarium.rpcUrls.default.http[0] };
      }
      return null;
    },
  }),
]);

const { connectors } = getDefaultWallets({
  appName: "My RainbowKit App",
  projectId: "YOUR_PROJECT_ID",
  chains,
});

const queryClient = new QueryClient();

const config = createConfig({
  autoConnect: true,
  connectors,
  publicClient,
  queryClient,
});

const Provider: FC<{ children: ReactNode }> = ({ children }) => {
  return (
    <WagmiConfig config={config}>
      <RainbowKitProvider chains={chains}>
        {children}
        <ToastContainer />
      </RainbowKitProvider>
      <QueryClientProvider client={queryClient}>
        <ReactQueryDevtools initialIsOpen={false} />
      </QueryClientProvider>
    </WagmiConfig>
  );
};

export default Provider;
