import React, { useCallback, useEffect } from "react";
import { toast } from "react-toastify";
import Spinner from "../Spinner";
import NFTCard from "./NFTCard";

import { useApproveToken, useGetOwnedTokens, useStake } from "../../hooks";
import useSelect from "../../hooks/useSelect";

export default function Stake() {
  const { selected, selectToken, clear } = useSelect();

  const { tokenIds, isLoading } = useGetOwnedTokens();
  const { isApproved, approveAll, isSuccess } = useApproveToken();
  const {
    stake,
    isSuccess: isStakeSuccess,
    isStaking,
  } = useStake({
    tokenIds: selected.map((i) => BigInt(i)),
    enabled: isApproved && selected.length > 0,
  });

  const handle = useCallback(() => {
    if (isApproved || isSuccess) {
      stake?.();
    } else {
      approveAll?.();
    }
  }, [approveAll, isApproved, isSuccess, stake]);

  useEffect(() => {
    if (isStakeSuccess) {
      toast.success("Stake Success");
      clear();
    }
    if (isStaking) {
      toast.info("Staking...");
    }
  }, [clear, isStakeSuccess, isStaking]);

  return (
    <div>
      <div className="flex justify-between items-center mt-5">
        {tokenIds.length > 0 ? (
          <div className="text-lg font-semibold text-white my-3">
            <label className="">Your Zombim: {tokenIds.length}</label>
          </div>
        ) : (
          <div className="text-blue-900 text-center mx-auto text-2xl my-6">
            You don't have any Zombim yet, you can buy&nbsp;
            <a
              className=""
              href="https://app.withmantra.com/market/collection/0xb1635a8a344afc0bdc0e8cf26954815644be7370/browse?chain_id=109&sort=0"
              target="_blank"
              rel="noreferrer"
            >
              <b>here</b>
            </a>
            &nbsp;or mint&nbsp;
            <a
              className=""
              href="https://mint.zombium.app"
              target="_blank"
              rel="noreferrer"
            >
              <b>here</b>
            </a>
          </div>
        )}

        <div>
          {tokenIds.length > 0 && selected.length > 0 && (
            <button
              type="button"
              className="bg-[#00000030] hover:bg-[#00000050] border-2 border-white border-solid text-white font-semibold px-5 py-2 rounded-xl"
              onClick={() => handle()}
            >
              {isLoading
                ? "Staking..."
                : isApproved
                ? "Stake Selected"
                : "Approve"}
            </button>
          )}
        </div>
      </div>
      <div className="grid grid-cols-5 gap-5 mt-5">
        {isLoading ? (
          <Spinner />
        ) : (
          tokenIds?.map((v, i) => (
            <NFTCard
              key={i}
              tokenID={v}
              selected={selected.findIndex((item) => item === Number(v)) !== -1}
              onClick={() => selectToken(Number(v))}
            />
          ))
        )}
      </div>
    </div>
  );
}
