import React from "react";

import zombiumImg from "../../assets/imgs/zombium.jpeg";

import StakedTokens from "./StakedTokens";
import Stake from "./Stake";
import Info from "./Info";

export function Staking() {
  return (
    <div className="pb-20">
      <div className="">
        <img
          src={zombiumImg}
          alt="shibs"
          className="w-full mx-auto pointer-events-none"
        />
      </div>
      <Info />
      <Stake />
      <StakedTokens />
    </div>
  );
}
