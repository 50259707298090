import {
  useContractWrite,
  usePrepareContractWrite,
  useWaitForTransaction,
} from "wagmi";

import { ZombiumStaking } from "../config";

export function useClaimRewards({
  tokenIds,
  enabled = tokenIds.length !== 0,
}: {
  tokenIds: readonly bigint[];
  enabled?: boolean;
}) {
  const { config } = usePrepareContractWrite({
    ...ZombiumStaking,
    functionName: "claimRewards",
    enabled,
  });

  const { data, write } = useContractWrite(config);
  const { isSuccess, isLoading } = useWaitForTransaction({
    hash: data?.hash,
  });

  return { claim: write, isSuccess, isLoading };
}
