import React from "react";

import logoImg from "../../assets/imgs/logo.jpeg";
import ConnectWallet from "./ConnectWallet";

export function Header() {
  return (
    <header id="header" className="relative w-full p-5 z-50 bg-slate-600">
      <div id="header-items" className="flex justify-between items-center">
        <div id="header-items-left" className="">
          <a href="/">
            <img
              src={logoImg}
              alt="logo"
              className="h-10 hover:hue-rotate-60 rounded-2xl"
            />
          </a>
        </div>
        <div id="header-items-right" className="">
          <ConnectWallet />
        </div>
      </div>
    </header>
  );
}
