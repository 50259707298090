import { useContractRead } from "wagmi";

import { ZombiumStaking } from "../config";

export function useGetRewardPerToken() {
  const { data, isLoading } = useContractRead({
    ...ZombiumStaking,
    functionName: "getRewardPerToken",
    scopeKey: "rewardPerToken",
  });

  return { rewardPerToken: data, isLoading };
}
