import React from "react";

export default function NFTCard({
  tokenID,
  selected,
  onClick,
  staked,
}: {
  tokenID: bigint;
  selected: boolean;
  onClick: () => void;
  staked?: boolean;
}) {
  return (
    <div
      className={`border ${
        selected && "border-blue-500"
      } rounded-lg hover:border-blue-500 hover:shadow-xl cursor-pointer`}
      onClick={onClick}
    >
      <img
        // src={`https://ipfs.io/ipfs/bafybeihfzqvprmads2lk3jjgzdewowka6f2d2zftnljg6tdsj2swyzm6tu/${tokenID}.png`}
        src={`https://assets-mantra.nyc3.cdn.digitaloceanspaces.com/shibarium/zombium/${tokenID}.png`}
        className="w-full rounded-lg"
        alt="shib"
      />

      <div className="p-3 flex flex-col gap-1 text-white">
        <div> {`Zombium #${tokenID}`}</div>
      </div>
      <div className=""></div>
    </div>
  );
}
