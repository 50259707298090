import { useAccount, useContractRead } from "wagmi";

import { ZombiumStaking } from "../config";

export function useGetStakedTokens() {
  const { address } = useAccount();
  const { data, ...rest } = useContractRead({
    ...ZombiumStaking,
    functionName: "userStakeInfo",
    args: [address!],
    enabled: !!address,
    scopeKey: "stakedTokens",
  });

  return { tokenIds: data?.[0], ...rest };
}
