import { Chain, Address } from "viem";
import { ZombiumStaking__factory } from "@sleepyshibs/staking";

export const STAKING_ADDRESS = process.env
  .REACT_APP_STAKING_ADDRESS! as Address;
export const NFT_ADDRESS = process.env.REACT_APP_NFT_ADDRESS! as Address;

console.log(NFT_ADDRESS, STAKING_ADDRESS);

export const IS_PRODUCTION = true;

export const shibarium: Chain = {
  network: "Shibarium",
  name: "Shibarium",
  rpcUrls: {
    default: { http: ["https://www.shibrpc.com"] },
    public: { http: ["https://www.shibrpc.com"] },
  },
  id: 109,
  nativeCurrency: {
    symbol: "BONE",
    name: "BONE",
    decimals: 18,
  },
  blockExplorers: {
    default: {
      name: "Shibariumscan",
      url: "https://www.shibariumscan.io",
    },
  },
};

export const ZombiumStaking = {
  address: STAKING_ADDRESS,
  abi: ZombiumStaking__factory.abi,
} as const;
