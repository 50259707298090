import React, { useEffect } from "react";
import { toast } from "react-toastify";
import { formatEther } from "viem";

import { useGetRewardsEarned, useClaimRewards } from "../../hooks";
import Spinner from "../Spinner";

export default function Claim({ tokenIds }: { tokenIds: bigint[] }) {
  const { rewards, isLoading, refetch } = useGetRewardsEarned();
  const {
    claim,
    isSuccess,
    isLoading: isClaiming,
  } = useClaimRewards({
    tokenIds: tokenIds || [],
  });

  useEffect(() => {
    if (isSuccess) {
      toast.success("Claim Success");
      refetch();
    }
    if (isClaiming) {
      toast.info("Claiming...");
    }
  }, [isSuccess, isClaiming, refetch]);

  return (
    <div className="my-8 flex flex-row gap-8 items-center justify-center">
      {isLoading ? (
        <Spinner />
      ) : (
        rewards !== undefined &&
        `Rewards Earned ${parseFloat(formatEther(rewards)).toFixed(10)} BONE`
      )}
      {rewards !== undefined && (
        <button
          onClick={claim}
          disabled={!claim}
          className="bg-[#00000030] hover:bg-[#00000050] border-2 border-white border-solid text-white font-semibold px-5 py-2 rounded-xl"
        >
          Claim Rewards
        </button>
      )}
    </div>
  );
}
