import React from "react";

export function Footer() {
  return (
    <footer className="text-white text-center w-full py-8 bg-slate-800">
      Copyright © 2023 Zombium - All Rights Reserved.
      <div className="flex flex-row gap-2 justify-center mt-2">
        <a href="https://x.com/Zombium_" target="_blank" rel="noreferrer">
          X(Twitter)
        </a>
        <a
          href="https://www.shibariumscan.io/token/0xb1635A8a344aFC0bDc0e8cF26954815644Be7370"
          target="_blank"
          rel="noreferrer"
        >
          Shibariumscan
        </a>
        <a href="https://mint.zombium.app" target="_blank" rel="noreferrer">
          Mint
        </a>
      </div>
    </footer>
  );
}
