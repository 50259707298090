import { useAccount } from "wagmi";

import { Footer, Header, Staking, NotConnected } from "./components";

function App() {
  const { isConnected } = useAccount();

  return (
    <div className="bg-black min-h-screen">
      <Header />
      <div id="app" className="container mx-auto">
        {isConnected ? <Staking /> : <NotConnected />}
      </div>
      <Footer />
    </div>
  );
}

export default App;
