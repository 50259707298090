import React, { useEffect } from "react";
import { toast } from "react-toastify";

import { useGetStakedTokens, useUnStake, useSelect } from "../../hooks";
import Spinner from "../Spinner";
import NFTCard from "./NFTCard";
import Claim from "./Claim";

export default function StakedTokens() {
  const { tokenIds: stakedTokenIds } = useGetStakedTokens();
  const { selected, selectToken, clear } = useSelect();
  const { unstake, isSuccess, isUnstaking } = useUnStake({
    tokenIds: selected.map((i) => BigInt(i)),
    enabled: selected.length > 0,
  });

  useEffect(() => {
    if (isUnstaking) {
      toast.info("Unstaking...");
    }
    if (isSuccess) {
      toast.success("Unstake Success");
      clear();
    }
  }, [isSuccess, isUnstaking, clear]);

  if (stakedTokenIds === undefined) return <Spinner />;
  if (stakedTokenIds.length === 0) return <></>;

  return (
    <div>
      <div className="flex justify-between items-center mt-5">
        <div className="text-lg font-semibold text-white  my-3">
          <label className="">Already Staked: {stakedTokenIds.length}</label>
        </div>
        <div>
          {selected.length > 0 && (
            <button
              type="button"
              className="bg-blue-500 hover:bg-blue-700 border border-blue-700 text-white font-semibold px-5 h-8 rounded-xl"
              onClick={unstake}
            >
              Unstake Selected
            </button>
          )}
        </div>
      </div>
      <div className="grid grid-cols-5 gap-5 mt-5">
        {stakedTokenIds.map((v, i) => (
          <NFTCard
            key={i}
            tokenID={v}
            selected={selected.findIndex((item) => item === Number(v)) !== -1}
            onClick={() => selectToken(Number(v))}
            staked={true}
          />
        ))}
      </div>
      <Claim tokenIds={selected.map((i) => BigInt(i))} />
    </div>
  );
}
