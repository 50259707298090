import { useEffect } from "react";
import {
  useContractWrite,
  usePrepareContractWrite,
  useQueryClient,
  useWaitForTransaction,
} from "wagmi";

import { ZombiumStaking } from "../config";
import { REVALIDATED_KEYS } from "./const";

export function useUnStake({
  enabled = false,
  tokenIds,
}: {
  tokenIds: bigint[];
  enabled?: boolean;
}) {
  const { config } = usePrepareContractWrite({
    ...ZombiumStaking,
    functionName: "withdraw",
    args: [tokenIds],
    enabled,
  });

  const { data, write } = useContractWrite(config);
  const { isSuccess, isLoading: isUnstaking } = useWaitForTransaction({
    hash: data?.hash,
  });

  const client = useQueryClient();

  useEffect(() => {
    if (isSuccess) {
      client.invalidateQueries({
        predicate: (query) =>
          REVALIDATED_KEYS.includes((query.queryKey[0] as any)["scopeKey"]),
      });
    }
  }, [client, isSuccess]);

  return { unstake: write, isSuccess, isUnstaking };
}
