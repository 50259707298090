import {
  useAccount,
  useContractRead,
  useContractWrite,
  usePrepareContractWrite,
  useWaitForTransaction,
} from "wagmi";
import { IERC721Enumerable__factory } from "@sleepyshibs/staking";
import { NFT_ADDRESS, STAKING_ADDRESS } from "../config";

export function useApproveToken() {
  const { address } = useAccount();

  const { data: isApproved, isLoading } = useContractRead({
    address: NFT_ADDRESS,
    abi: IERC721Enumerable__factory.abi,
    functionName: "isApprovedForAll",
    args: [address!, STAKING_ADDRESS],
    enabled: !!address,
  });

  const { config: approveAllConfig } = usePrepareContractWrite({
    address: NFT_ADDRESS,
    abi: IERC721Enumerable__factory.abi,
    functionName: "setApprovalForAll",
    args: [STAKING_ADDRESS, true],
    enabled: !isLoading && !isApproved,
  });

  const { data: approve, write } = useContractWrite(approveAllConfig);

  const { isSuccess, isLoading: isApproving } = useWaitForTransaction({
    hash: approve?.hash,
  });

  return {
    isLoading,
    isApproved,
    approveAll: write,
    isApproving,
    isSuccess,
  };
}
